import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { PopupsService } from '@shared/popups/popups.service';
import { TrialPopupComponent } from './trial-popup/trial-popup.component';

@Injectable({
  providedIn: 'root',
})
export class AddGameService {
  constructor(
    private popupsService: PopupsService,
    private router: Router,
  ) {}

  add() {
    if (this.isActivePlan()) {
      this.router.navigate(['/account/choose-game']);
      return;
    }
    this.popupsService
      .showDialog(TrialPopupComponent, { panelClasses: 'trial' })
      .afterClosed()
      .subscribe(() => {});
  }

  isActivePlan() {
    return true; // TODO isActivePlan
  }
}
